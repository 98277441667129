<template>
  <div><span>Details</span></div>
</template>

<script>
import RootEvents from '@/constants/rootEvents'
import { BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      name: 'model',
      isOnline: false,
    }
  },
  watch: {
    '$route.params.id': {
      handler() {
        this.getDetails()
      },

      immediate: true,
    },
  },
  methods: {
    getDetails() {
      return this.$root.$emit(RootEvents.SET_PAGE_TITLE, `${'Jørgen'} ${'Hagen'}`)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/master-variables.scss';

.max-w-200px {
  max-width: 200px;
}

.prozess-inquiry {
  span {
    white-space: nowrap;
  }

  &__table {
    .b-table-sticky-column {
      left: unset !important;
      right: 0 !important;
    }
    td.b-table-sticky-column {
      background: var(--colour--table-row) !important;
    }
  }
}

.prozess-inquiry__dropdown {
  .vs--open {
    div > div > .vs__selected {
      margin-top: 10px !important;
    }
  }
  div > div > div.vs__selected-options {
    height: 35px;
    overflow: hidden;
    width: 140px;
    .vs__selected {
      margin-top: 0px;
      height: 100%;
    }
  }
}
</style>
